import { lab1,
  lab2,
  lab3,
  lab4,
  lab5,
  lab6,
  lab7,
  lab8,
  lab9,
  lab10,
  lab11,
  lab12,
  lab13,
  lab14,
  lab15,
  lab16,  } from "../assets"

const lab = [
  {
    id: 1,
    
    cover: lab1,
    name: "Advanced-Clinical-Centrifuge",
    price: 100,
  },
  {
    id: 2,
     
    cover: lab2,
    name: "Low-Temperature-Deep-Vertical-Freezer",
    price: 20,
  },
  {
    id: 3,
    
    cover: lab3,
    name: "Biological-Microscope",
    price: 200,
  },
  {
    id: 4,
    
    cover: lab4,
    name: "Constant Temperature and Humidity Incubator",
    price: 50,
  },
  {
    id: 5,
    
    cover: lab5,
    name: "Constant-Temperature Drying Oven",
    price: 100,
  },
  {
    id: 6,
    
    cover: lab6,
    name: "Forced Air Drying Oven",
    price: 100,
  },
  {
    id: 7,
    
    cover: lab7,
    name: "High Temperature Drying Oven",
    price: 100,
  },
  {
    id: 8,
    
    cover: lab8,
    name: "Laboratory-Pharmaceutical-Refrigerator-Oxyaider",
    price: 100,
  },
  {
    id: 9,
    
    cover: lab9,
    name: "Lighting Incubator",
    price: 100,
  },
  {
    id: 10,
   
    cover: lab10,
    name: "Low Speed Centrifuge",
    price: 100,
  },
  {
    id: 11,
    
    cover: lab11,
    name: "Low Temperature CO2 Incubator",
    price: 100,
  },
  {
    id: 12,
    
    cover: lab12,
    name: "Mini Centrifuge",
    price: 100,
  },
  {
    id: 13,
    
    cover: lab13,
    name: "Multifunctional Incubator",
    price: 100,
  },
  {
    id: 14,
    
    cover: lab14,
    name: "Vacuum Drying Oven",
    price: 100,
  },
  {
    id: 15,
    
    cover: lab15,
    name: "Laboratory-Biological-Binocular-Microscope",
    price: 100,
  },
  {
    id: 16,
    
    cover: lab16,
    name: "Centrifuge",
    price: 100,
  },
];

export default lab;



