import React from "react"
import styles from "../style";
import SlideCard from "./SlideCard"

const SlideDeals = () => {
  return (
    <>
      <section className={`${styles.paddingY} HotDealsSlide`}>
        <div className='container'>
          <SlideCard />
        </div>
      </section>
    </>
  )
};

export default SlideDeals;