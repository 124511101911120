import React from "react";
import { projectmgmt, shipping, tech, consulting, repairs, purchasing } from "../assets";


const data =[
  {
    name: "3D CAD Workflow",
    detail: "Project Management",
    image: projectmgmt,
  },
  {
    name: "Product delivery",
    detail: "Products",
    image: shipping,
  },
  {
    name: "Technology integration",
    detail: "Technology",
    image: tech,
  },
  {
    name: "Consultation Services",
    detail: "Consultation",
    image: consulting,
  },
  {
    name: "Equipment repairs",
    detail: "Repairs",
    image: repairs,
  },
  {
    name: "Accessories",
    detail: "Consumables & Spareparts",
    image: purchasing,
  },
];
export default data;