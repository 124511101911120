import { r1, r2, projectmgmt, shipping, repairs, purchasing, facebook, instagram, linkedin, youtube, whatsapp, rimsa, smartsimple, reanimed, detes, biobase, send, shield, star  } from "../assets";

export const navLinks = [
  {
    id: "home",
    title: "Home",
    href: "/home",
  },
  {
    id: "products",
    title: "Products",
    href: "/products",
  },
  {
    id: "expertise",
    title: "Expertise",
    href: "/expertise", 
  },
  {
    id: "company",
    title: "Company",
    href: "/company",
  },
  {
    id: "support",
    title: "Support",
    href: "/support",
  },
  {
    id: "contact",
    title: "Contact",
    href: "/contact",
  },
];

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Consultancy Services",
    content:
      "We offer medical training, servicing and repairs and research.",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Medical Equipment",
    content:
      "We offer a variety of medical equipment to medical centers all over Sub-Saharan Africa.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Over The Counter(OTC) Devices",
    content:
      "We offer medical equipment that has established safety profiles for use by consumers in non-clinical environments.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "..",
    name: "Anaesthesia Machine S6100_1 ",
    title: "Lab",
    img: "..",
  },
  {
    id: "feedback-2",
    content:
      "..",
    name: "Anaesthesia Machine S6100_1",
    title: "Medical Gases",
    img: "..",
  },
  {
    id: "feedback-3",
    content:
      "..",
    name: "Anaesthesia Machine S6100_1",
    title: "Theatre",
    img: "..",
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "Precision In Healthcare",
    
  },
];

export const footerLinks = [
  {
    title: "Products",
    links: [
      {
        name: "Medical Gases",
        href: "/products",
      },
      {
        name: "Dental",
        href: "/products",
      },
      {
        name: "Radiology",
        link: "/products",
      },
      {
        name: "Theatre",
        href: "/products",
      },
      {
        name: "CSSD Equipment",
        href: "/products",
      },
    ],
  },
  {
    title: "Company",
    links: [
      {
        name: "About",
        href: "/company",
      },
      {
        name: "Products",
        href: "/products",
      },
      {
        name: "Expertise",
        href: "/expertise",
      },
      {
        name: "Contact",
        link: "/contact",
      },
      {
        name: "Support",
        href: "/support",
      },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        name: "Terms & Services",
        href: "/support",
      },
      {
        name: "Terms of Use",
        href: "/support",
      },
      {
        name: "Refund Policy",
        href: "/support",
      },
      
    ],
  },
  {
    title: "Quick Links",
    links: [
      {
        name: "Full Catalogue",
        href: "https://www.hosphcaresolutionsltd.co.ke/Catalogue",
      },
      {
        name: "Become a Partner",
        href: "/contact",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: whatsapp,
    link: "https://wa.me/+254729327268",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/hosphcaresolutionsltd",
  },
  {
    id: "social-media-3",
    icon: instagram,
    link: "https://www.instagram.com/hosphcaresolutionslimited/",
  },
  {
    id: "social-media-4",
    icon: youtube,
    link: "https://www.youtube.com/channel/UC_8ROtyNfPHpMBEo67iMTkA",
  },
  {
    id: "social-media-5",
    icon: linkedin,
    link: "https://www.linkedin.com/in/hosphcare-solutions-limited-6a9a8528b/",
  },
];

export const clients = [
  {
    id: "client-1",
    logo: rimsa,
  },
  {
    id: "client-2",
    logo: smartsimple,
  },
  {
    id: "client-3",
    logo:  reanimed,
  },
  {
    id: "client-4",
    logo: detes,
  },
  {
    id: "client-5",
    logo: biobase,
  },
];

export const anaesthesia = [
  {
    id: "01",
    img: r1,
    name: "Anaesthesia Machine S6100_1 ",
    
  },
  {
    id: "01",
    img: r2,
    name: "Anaesthesia Machine S6100_1 ",
    
  },

];

export const experiences = [
  {
    title: "Sales",
    date: "March 2020 - April 2021",
    points: [
      "Our structured sales process starts with one of our experienced account managers taking the time to develop an accurate and detailed understanding of what you require.",
      "Once your requirements are understood we ensure that the product we recommend is fit for purpose, both now and into the future.",
    ],
  },
  {
    title: "Procurement",
    date: "Jan 2021 - Feb 2022",
    points: [
      "We travel the globe searching for products that health professionals tell us they need.",
      "We critically assess each product’s design, performance, reliability, longevity and price to ensure that every HSL product represents best value over its life-span.",
      "Where an off the shelf device does not meet your exact requirements we may design one and have it manufactured here in Kenya to suit your needs.",
    ],
  },
  {
    title: "Specialists",
    date: "Jan 2022 - Jan 2023",
    points: [
      "Our product managers are trained by our manufacturing suppliers in all aspects of the equipment’s functions, uses and the maintenance of the equipment.",
      "Keeping in close contact with the manufacturer to ensure our supply is working smoothly and that we stay up-to-date with the latest technology.",
    ],
  },
  {
    title: "Other Services",
    date: "Jan 2023 - Present",
    points: [
      "HSL has a technical service team that supports its products, from in-service training through to complete operating table rebuilds.",
      "HSL installs equipment, lights, pendants, digital integration, sterilizers Dental, Medical Gases and more.",
      "Also project manages complete medical fit-outs.",
    ],
  },
];

const featuresData = [
  {
    image: shipping,
    title: "Sub-Saharan Delivery",
    decs: "We offer delivery of equipment across East African and the Sub-Saharan Region.",
  },
  {
    image: purchasing,
    title: "Safe Payment",
    decs: "We offer competitive prices on our products at any range.",
  },
  {
    image: projectmgmt,
    title: "Shop With Confidence ",
    decs: "We offer safe and quality assurance on all our products.",
  },
  {
    image: repairs,
    title: "24/7 Support ",
    decs: "We offer maintenance and support 24/7.",
  },
];
export default featuresData;





