import { Adapter1, Adapter3, Adapter6, Adapter7  } from "../assets"

const medicalgasesadapter = [
  {
    id: 1,
    
    cover: Adapter1,
    name: "Diaphragm Vacuum Regulator With Rail",
    price: 100,
  },
  {
    id: 3,
    
    cover: Adapter3,
    name: "Oxygen Concentrators",
    price: 200,
  },
  {
    id: 6,
    
    cover: Adapter6,
    name: "Medical Hoses",
    price: 100,
  },
  {
    id: 7,
    
    cover: Adapter7,
    name: "Oxygen Therapy Device",
    price: 100,
  },
];

export default medicalgasesadapter;
