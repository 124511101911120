import { suction1, suction2, suction3, suction4, } from "../assets"

const suctionmachine = [
  {
    id: 1,
    
    cover: suction1,
    name: "Smart-Power 1",
    price: 100,
  },
  {
    id: 2,
    
    cover: suction2,
    name: "Surgical-Suction-Emivac",
    price: 20,
  },
  {
    id: 3,
    
    cover: suction3,
    name: "Surgical-Suction-Flovac-Trolley",
    price: 200,
  },
  {
    id: 4,
    
    cover: suction4,
    name: "Surgical-Suction-Hospivac",
    price: 50,
  },
  {
    id: 5,
    
    cover: "./images/flash/flash-1.png",
    name: "  SCD 600",
    price: 100,
  },
  {
    id: 6,
    
    cover: "./images/flash/flash-3.png",
    name: "  Infusion Pump M300A",
    price: 100,
  },
];

export default suctionmachine;



