import { monitor1, monitor2, monitor3, monitor4, monitor5, monitor6, monitor7 } from "../assets"

const monitors = [
  {
    id: 1,
    
    cover: monitor1,
    name: "Monochrome Monitors",
    price: 100,
  },
  {
    id: 2,
    
    cover: monitor2,
    name: "Touch Panel Color Monitors",
    price: 20,
  },
  {
    id: 3,
    
    cover: monitor3,
    name: "Hybrid Medical Display",
    price: 200,
  },
  {
    id: 4,
    
    cover: monitor4,
    name: "Mammography Display Monitor",
    price: 50,
  },
  {
    id: 5,
    
    cover: monitor5,
    name: "Medical Display Monitor",
    price: 100,
  },
  {
    id: 6,
    
    cover: monitor6,
    name: "Surgical Medical Monitor",
    price: 100,
  },
  {
    id: 6,
    
    cover: monitor7,
    name: "Diagnostic Medical Monitor",
    price: 100,
  },
];

export default monitors;



