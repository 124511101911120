const Button3 = ({ onClickHandler, value, title }) => {
    return (
      <a href="/contact">
        <button onClick={onClickHandler} value={value} className="btns">
          Contact Us
        </button>
      </a>
    );
  };
  
  export default Button3;