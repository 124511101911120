import { dentalvacuum, dentalapex, dentalairmotor, aircompressor1, aircompressor2, aircompressor3, aircompressor4, aircompressor5, aircompressor6, aircompressor7, dentalchair1, dentalchair2, dentalchair3, dentalchair4, dentalchair5, dentalchair6 } from "../assets"

const dental = [
  {
    id: 1,
    
    cover: dentalvacuum,
    name: "Dental Vacuum",
    price: 100,
  },
  {
    id: 2,
    
    cover: dentalapex,
    name: "Economic-Dental-Apex-Locator-Oxyaider",
    price: 20,
  },
  {
    id: 3,
    
    cover: dentalairmotor,
    name: "Dental-Air-Motor-4-Hole-Contra-Angle-Straight-Handpiece",
    price: 200,
  },
  {
    id: 4,
    
    cover: aircompressor1,
    name: "1.5EW Oil-free air compressor",
    price: 50,
  },
  {
    id: 5,
    
    cover: aircompressor2,
    name: "2EW Oil-free air compressor",
    price: 100,
  },
  {
    id: 6,
    
    cover: aircompressor3,
    name: "3EW Oil-free air compressor",
    price: 100,
  },
  {
    id: 7,
    
    cover: aircompressor4,
    name: "Dental-Bone",
    price: 100,
  },
  {
    id: 8,
    
    cover: aircompressor5,
    name: "Silent-Dental-Air-Compresor",
    price: 100,
  },
  {
    id: 9,
    
    cover: dentalchair1,
    name: "TS-PRO208 Plus",
    price: 100,
  },
  {
    id: 10,
    discount: 50,
    cover: dentalchair2,
    name: "TS-TOP300 Folding",
    price: 100,
  },
  {
    id: 11,
    
    cover: dentalchair3,
    name: "TS-TOP301 Standard",
    price: 100,
  },
  {
    id: 12,
    
    cover: dentalchair4,
    name: "TS-TOP301 ROTARY",
    price: 100,
  },
  {
    id: 13,
    
    cover: dentalchair5,
    name: "Dental-Unit-Chair",
    price: 100,
  },
  {
    id: 14,
    
    cover: dentalchair6,
    name: "Medical-Dental-Unit-Full-Set",
    price: 100,
  },
  {
    id: 15,
    
    cover: aircompressor6,
    name: "Air-Plus 1",
    price: 50,
  },
  {
    id: 16,
    
    cover: aircompressor7,
    name: "Air-Pro 1",
    price: 100,
  },
];

export default dental;



