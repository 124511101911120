import { 
  pendant1,
  pendant2,
  pendant3,
  pendant4,
  pendant5,
  pendant6,
  pendant7,
  pendant8,
  pendant9,  } from "../assets"

const pendantarm = [
  {
    id: 1,
    
    cover: pendant1,
    name: "DOUBLE ARM MOTORIZED HEXMOD PENDANT",
    price: 100,
  },
  {
    id: 2,
     
    cover:   pendant2,
    name: "DOUBLE ARM MOTORIZED KALE PENDANT",
    price: 20,
  },
  {
    id: 3,
    
    cover:   pendant3,
    name: "DOUBLE ARM NON-MOTORIZED CALE PENDANT",
    price: 200,
  },
  {
    id: 4,
    
    cover:   pendant4,
    name: "FIXED PENDANT CASTLE",
    price: 50,
  },
  {
    id: 5,
    
    cover:  pendant5,
    name: "FIXED PENDANT HEXMOD",
    price: 100,
  },
  {
    id: 6,
    
    cover:  pendant6,
    name: "LIFT PENDANT",
    price: 100,
  },
  {
    id: 7,
    
    cover:   pendant7,
    name: "SINGLE ARM MOTORIZED HEXMOD PENDANT",
    price: 100,
  },
  {
    id: 8,
    
    cover:   pendant8,
    name: "SINGLE ARM NON-MOTORIZED CALE PENDANT",
    price: 100,
  },
  {
    id: 9,
    
    cover:   pendant9,
    name: "TANDEM-PENDANT",
    price: 100,
  },
];

export default pendantarm;
