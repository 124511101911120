import React from "react";
import styles from "../style";
import "../index.css";

const Button = ({ styles }) => (
  <a href="/products">
    <button type="button" className={`py-4 px-6 font-poppins font-medium text-[16px] color-blue text-black bg-blue-gradient rounded-sm outline-none blue-border ${styles}`}>
      Our Products
    </button>
  </a>
);

export default Button;
