import React from 'react';
import styles from '../style';
import "../index.css";
import featuresData from '../constants';

const Features = () => {
  return (
    <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
      <section className='wrapper background'>
        <div className='container flex-row flex-wrap grid2 sm:mb-10 mb-5'>
          {featuresData.map((val, index) => {
          return (
            <div className='product' key={index}>
              <div className= 'img icon-circle'>
               <img src={val.image} alt="" object-contain width={50} height={50} relative z={5}/>
              </div>
              <h3>{val.title}</h3>
              <p>{val.decs}</p>
            </div>
            )
          })}
        </div> 
      </section>
    </div>
  )
}
export default Features;
