import { ultrasoundtrolley, anaesthesiamachine, ultrasoundoxyaider, anaestheticmachine, ultrasoundmachine, resuscitator } from "../assets"

const anaesthesia = [
  {
    id: 1,
    
    cover: ultrasoundtrolley,
    name: "Ultrasound Trolley",
    price: 100,
  },
  {
    id: 2,
    
    cover: anaesthesiamachine,
    name: "Anaesthesia Machine",
    price: 20,
  },
  {
    id: 3,
    
    cover: ultrasoundoxyaider,
    name: " Ultrasound Oxyaider",
    price: 200,
  },
  {
    id: 4,
    
    cover: anaestheticmachine,
    name: "Anaesthetic Machine",
    price: 50,
  },
  {
    id: 5,
    
    cover: ultrasoundmachine,
    name: "3D-Laptop Ultrasound Machine",
    price: 100,
  },
  {
    id: 6,
    
    cover: resuscitator,
    name: "Silicone Manual Resuscitator",
    price: 100,
  },
];

export default anaesthesia;



