import { functionbed, functionselectricbed, walkingframe, traumastretcher, gynecologyexaminationbed, deliverybed, foldingchair, recliningchair, manualbed, theatre1, theatre2, theatre3, theatre4, theatre5, theatre6, theatre7, theatre8, } from "../assets";

const theatre = [
  {
    id: 15,
    
    cover: theatre6,
    name: "Orhtopedic Traction Set",
    price: 100,
  },
  {
    id: 16,
    
    cover: theatre7,
    name: "Neurosurgery skull clamp",
    price: 100,
  },
  {
    id: 1,
    
    cover: functionbed,
    name: "2function bed",
    price: 100,
  },
  {
    id: 2,
    
    cover: functionselectricbed,
    name: "5-functions electric bed",
    price: 20,
  },
  {
    id: 3,
    
    cover: gynecologyexaminationbed,
    name: "Gynecology-Examination-Bed",
    price: 200,
  },
  {
    id: 10,
    
    cover: theatre1,
    name: "Manual MultiPurpose OT Table",
    price: 100,
  },
  {
    id: 11,
    
    cover: theatre2,
    name: "Mobile Imaging Table",
    price: 100,
  },
  {
    id: 4,
    
    cover: deliverybed,
    name: "Fully-electrical-delivery-bed",
    price: 50,
  },
  {
    id: 5,
    
    cover: foldingchair,
    name: "Patients Folding Chair",
    price: 100,
  },
  {
    id: 6,
    
    cover: recliningchair,
    name: "IV-RECLINING-CHAIR",
    price: 100,
  },
  {
    id: 7,
    
    cover: manualbed,
    name: "Manual-Nursing-Patient-Hospital-Bed",
    price: 100,
  },
  {
    id: 8,
    
    cover: traumastretcher,
    name: "Trauma-Stretcher",
    price: 100,
  },
  {
    id: 9,
    
    cover: walkingframe,
    name: "Walking Frame",
    price: 100,
  },
  {
    id: 12,
    
    cover: theatre3,
    name: "Mechanical Surgery Table",
    price: 100,
  },
  {
    id: 13,
    
    cover: theatre4,
    name: "Orhtopedic Traction Set. Carbon Fiber",
    price: 100,
  },
  {
    id: 14,
    
    cover: theatre5,
    name: "Rectal positioning set",
    price: 100,
  },
  {
    id: 15,
    
    cover: theatre6,
    name: "Orhtopedic Traction Set",
    price: 100,
  },
  {
    id: 16,
    
    cover: theatre7,
    name: "Neurosurgery skull clamp",
    price: 100,
  },
  {
    id: 17,
    
    cover: theatre8,
    name: "Spinal Cord positioning device",
    price: 100,
  },
];

export default theatre;



