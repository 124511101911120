import { Adapter3, ultrasoundtrolley, anaesthesiamachine, cssd1, recliningchair, dentalchair6 } from "../assets";


const data =[
  {
    name: "Ultrasound Trolley",
    detail: "Trolleys",
    image: ultrasoundtrolley,
  },
  {
    name: "Oxygen Conentrator",
    detail: "Medical Gases",
    image: Adapter3,
  },
  {
    name: "Anaesthesia Machine",
    detail: "Anaesthesia",
    image: anaesthesiamachine,
  },
  {
    name: "Portable Pressure Steam Autoclave",
    detail: "CSSD",
    image: cssd1,
  },
  {
    name: "Reclining Chair",
    detail: "Furniture",
    image: recliningchair,
  },
  {
    name: "Dental Unit",
    detail: "Dental",
    image: dentalchair6,
  },
];
export default data;