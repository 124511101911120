import styles from "../style";
import Button3 from "./Button3";

const CTA = () => (
  <section className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-row flex-col bg-black-img rounded-[20px] box-shadow`}>
    <div className="flex-1 flex flex-col">
      <h2 className={`${styles.heading2} text-white`}>Staying abreast with technology!</h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5 text-white`}>
        We know that in providing our communities with the very best results in healthcare, excellent equipment is just the beginning.
      </p>
    </div>

    <div className={`${styles.flexCenter} sm:ml-10 ml-0 sm:mt-0 mt-10`}>
      <Button3 />
    </div>
  </section>
);

export default CTA;
