import { cabinet1, cabinet2, cabinet3, cabinet4, cabinet5, cabinet6, cabinet7, cabinet8, cabinet9, } from "../assets"

const biosafetycabinet = [
  {
    id: 1,
    
    cover: cabinet1,
    name: "Class I Biological Safety Cabinet",
    
  },
  {
    id: 2,
    
    cover:   cabinet2,
    name: "Class II A2 Biological Safety Cabinet",
    
  },
  {
    id: 3,
    
    cover:   cabinet3,
    name: "Class III Biological Safety Cabinet Level 3",
    
  },
  {
    id: 4,
    
    cover:   cabinet4,
    name: "Class III Biological Safety Cabinet",
    
  },
  {
    id: 5,
    
    cover:   cabinet5,
    name: "Class Ⅱ Biological Safety Cabinet 11231 PRO",
    
  },
  {
    id: 6,
    
    cover:   cabinet6,
    name: "Cytotoxic Safety Cabinet",
    
  },
  {
    id: 7,
    
    cover:   cabinet7,
    name: "Double-side Class II A2 Biological Safety Cabinet",
    
  },
  {
    id: 8,
    
    cover:   cabinet8,
    name: "EN Certified Biological Safety Cabinet",
    
  },
  {
    id: 9,
    
    cover:   cabinet9,
    name: "New EN Certified Biological Safety Cabinet BSC-2FA2-HA",
    
  },
];

export default biosafetycabinet;



