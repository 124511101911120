import { cssd1, cssd2, cssd3, cssd4, cssd5, cssd6, cssd7, cssd8, cssd9,} from "../assets"

const cssd = [
  {
    id: 1,
    
    cover: cssd1,
    name: "Portable Pressure Steam-Autoclave",
    price: 100,
  },
  {
    id: 2,
    
    cover: cssd2,
    name: "Electrothermal Pressure steam-sterilizer",
    price: 20,
  },
  {
    id: 3,
    
    cover: cssd3,
    name: "Portable Steam-Autoclave",
    price: 200,
  },
  {
    id: 4,
    
    cover: cssd4,
    name: "Portable-Sterilizer",
    price: 50,
  },
  {
    id: 5,
    
    cover: cssd5,
    name: "Sterilizer Portable-Autoclave",
    price: 100,
  },
  {
    id: 6,
    
    cover: cssd6,
    name: "UV-Sterilizer",
    price: 100,
  },
  {
    id: 7,
    
    cover: cssd7,
    name: "Ultrasonic-Scaler-A2",
    price: 100,
  },
  {
    id: 8,
    
    cover: cssd8,
    name: "Class B Steam Steriliser",
    price: 100,
  },
  {
    id: 9,
    
    cover: cssd9,
    name: "Water Distiller",
    price: 100,
  },
];

export default cssd;



