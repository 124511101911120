import { bedheadunit1,
  bedheadunit2,
  bedheadunit3,
  bedheadunit4,
  bedheadunit5,
  bedheadunit6,
  bedheadunit7,
  bedheadunit8,
  bedheadunit9,  } from "../assets"

const bedheadunit = [
  {
    id: 1,
    
    cover: bedheadunit1,
    name: "ARYA Bed-Head Unit",
    price: 100,
  },
  {
    id: 2,
     
    cover:   bedheadunit2,
    name: "CAT Bed-Head Unit",
    price: 20,
  },
  {
    id: 3,
    
    cover:   bedheadunit3,
    name: "DERIN Bed-Head Unit",
    price: 200,
  },
  {
    id: 4,
    
    cover:   bedheadunit4,
    name: "MODULA Bed-Head Unit",
    price: 50,
  },
  {
    id: 5,
    
    cover:   bedheadunit5,
    name: "SAHRA Bed-Head Unit",
    price: 100,
  },
  {
    id: 6,
    
    cover:   bedheadunit6,
    name: "SOFT Bed-Head Unit",
    price: 100,
  },
  {
    id: 7,
    
    cover:   bedheadunit7,
    name: "Bed-Head Unit",
    price: 100,
  },
  {
    id: 8,
    
    cover:   bedheadunit8,
    name: "Bed-Head Unit",
    price: 100,
  },
  {
    id: 9,
    
    cover:   bedheadunit9,
    name: "mobilya Bed-Head Unit",
    price: 100,
  },
];

export default bedheadunit;
