import { xraylow, xrayhigh, xray1, xray2, xray3, xray4, xray5, xray6, xray7, xray8 } from "../assets" 

const radiology = [
  {
    id: 1,
    
    cover: xraylow,
    name: "X-RAY RX70 AC Low Frequency",
    price: 100,
  },
  {
    id: 2,
    
    cover: xrayhigh,
    name: "X-RAY RX70 AC High Frequency",
    price: 20,
  },
  {
    id: 3,
    
    cover: xray1,
    name: "CT Scanner",
    price: 200,
  },
  {
    id: 4,
    
    cover: xray2,
    name: "MRI Imaging",
    price: 50,
  },
  {
    id: 5,
    
    cover: xray3,
    name: "Panoramic x-ray film viewer",
    price: 100,
  },
  {
    id: 6,
    
    cover: xray4,
    name: "Wall-mounted X-RAY UNIT",
    price: 100,
  },
  {
    id: 7,
    
    cover: xray5,
    name: "Diagnostic x-ray system",
    price: 100,
  },
  {
    id: 8,
    
    cover: xray6,
    name: "ICU - BRIDGE TYPE",
    price: 100,
  },
  {
    id: 9,
    
    cover: xray7,
    name: "ICU - H TYPE",
    price: 100,
  },
  {
    id: 10,
    
    cover: xray8,
    name: "Suction Unit",
    price: 100,
  },

];

export default radiology;



