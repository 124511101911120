const Faq = [
  {
    question: 'How do I get billed for services?',
    answer: 'We accept payment from most insurance companies for equipment and supplies. In some cases, your insurance may limit the number and type of supplies, require prior authorization, and have co-payments and/or deductibles. It is your responsibility to notify us of any changes in your insurance coverage. Rental equipment is charged on a monthly basis in most cases. Some types of equipment may be purchased rather than rented. Please contact our billing office if you would like to have your equipment changed to a purchase. Insurance verification is done prior to providing you with equipment/supplies. We will file a claim directly with your insurance company on your behalf, with the physician’s order, and request payment be sent directly to Sparrow Medical Supply.'
  },
  {
    question: 'Does HSL provide onsite training or distance learning opportunities?',
    answer: 'We value new interested parties to the field of medical engineering and we are pleased to have a training program for all those interested. Please reach out to find out more on our training services.'
  },
  {
    question: 'What type of ongoing support does HSL provide?',
    answer: 'We offer consultation service, equipment installation, project management and 3D CAD project workflow. We offer delivery services in all locations and 24/7 support on all equipment installed or projects done.'
  },
  {
    question: 'Does HSL provide access to the spare parts I will need?',
    answer: 'Through the support of our partners, we offer spareparts, accessories as well as upgrades to all equipment.'
  },
  {
    question: 'Does extreme heat and humidity in various regions impact equipment functionality?',
    answer: 'Yes, we tackle that through our vigourous study of the installtion environment to ensure all equipment is in the perfect environment to meet your needs for years to come.'
  },
  {
    question: 'Do all equipment work well with a transformer?',
    answer: 'We ensure that all products we bring to you are up to standard and meet the minimum requirements to function properly.'
  },
  {
    question: 'I was approved for an item, can I pay extra to get a better model?',
    answer: 'Every product we offer you, has spareparts, accessories as well as upgrades if needed.'
  },
  {
    question: 'I need my item repaired, what do I do?',
    answer: 'Reach out to us for any assistance and we will advice accordingly.'
  },

];

export default Faq;