import styles, { layout } from "../style";
import { cardimg } from "../assets"
import Button from "./Button";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { slidersettings } from "./Common";
import 'swiper/css';
import './CardDeal.css';
import sliderdata from '../constants/slider';

const CardDeal = () => (
  <section className={`${styles.paddingY} flex md: flex-col`}>
    <div className={layout.sectionImg}>
      <img src={cardimg} alt="" className="w-[40%] h-[40%] relative z-[5]" />
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>
        A passion <br className="sm:block hidden" /> for Healthcare
        </h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5 text-black`}>
        We understand the demands placed on today’s healthcare professionals, and offer you a total solution in quality, reliable equipment and support extending into the future.
        </p>

        <Button styles={`mt-10`} />
      </div>
    </div>

    <section className={layout.section}>
      <div className="paddings innerwidth r-container">
        <Swiper {...slidersettings}> 
          <SliderButtons />      
          {sliderdata.map((card, i)=> (
              <SwiperSlide>
                <a href="/products">
                  <div className="r-card flex md: flex-col">
                    <img src={card.image} alt="" />
                    <span className="secondaryText r-price">
                      <span style={{ color: "blue" }}></span>
                      <span className="color-blue text-[12px]">{card.detail}</span>
                    </span>
                    <span className="primaryText">{card.name}</span>
                  </div>
                </a>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </section>
  </section>
);

export default CardDeal;

const SliderButtons = ()=> {
  const swiper = useSwiper();
  return (
    <div className="flexCenter r-buttons">
      <button onClick={()=> swiper.slidePrev()}>&lt</button>
      <button onClick={()=> swiper.slideNext()}>&gt</button>
    </div>
  )
}
