import{ light1, light2, light3, light4, light5, light6, light7, light8, light9, light10, light11, light12, } from "../assets"

const medicallights = [
  {
    id: 1,
   
    cover: light1,
    name: "N-Series",
    price: 100,
  },
  {
    id: 2,
    
    cover: light2,
    name: "Observa Alfa-fix",
    price: 20,
  },
  {
    id: 3,
    
    cover: light3,
    name: "Observa Alfa-flex",
    price: 200,
  },
  {
    id: 4,
    
    cover: light4,
    name: "Observa Prima-flex Goosneck-arm",
    price: 50,
  },
  {
    id: 5,
    
    cover: light5,
    name: "Observa Primaled ",
    price: 100,
  },
  {
    id: 6,
    
    cover: light6,
    name: "Pentaled 12-28",
    price: 100,
  },
  {
    id: 7,
    
    cover: light7,
    name: "Pentaled 63 N",
    price: 100,
  },
  {
    id: 8,
    
    cover: light8,
    name: "Pentaled28",
    price: 100,
  },
  {
    id: 9,
   
    cover: light9,
    name: "Quattroluci LED",
    price: 100,
  },
  {
    id: 10,
    
    cover: light10,
    name: "Saturno-LED",
    price: 100,
  },
  {
    id: 11,
    
    cover: light11,
    name: "U 29",
    price: 100,
  },
  {
    id: 12,
    
    cover: light12,
    name: "Unica",
    price: 100,
  },

];

export default medicallights;



