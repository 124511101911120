import { React } from "react";
import emailjs from 'emailjs-com';
import { Form, Input, TextArea, Button } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import styles from "../style";
import "../index.css"
import ReactWhatsapp from "react-whatsapp";
import Footer from "../components/Footer";
import EarthCanvas from "../components/canvas/Earth";


const SERVICE_ID = "service_pcqs6uq";
const TEMPLATE_ID = "template_95sq7eo";
const USER_ID = "utzc-1oTb6tx2UGHi";

const Contact = () => {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'Message Sent Successfully'
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon: 'error',
          title: 'Ooops, something went wrong',
          text: error.text,
        })
      });
    e.target.reset()
  };


    return (
      <section className={`flex flex-col ${styles.paddingY}`}>
        <div className={`${styles.flexCenter} flex md:flex-row flex-col justify-center ${styles.paddingX} ${styles.paddingY}`}>
          <h2 className={`${styles.heading2} xs:text-[30px] justify-center`}>
          Get in touch with Us.
          </h2>
          <ReactWhatsapp number="+254 729327268" className="btns1" message="Hello, I would like to know more about what your company offers.">Whatsapp Inquiry</ReactWhatsapp>
        </div>
        <section id="hero" className={`flex md:flex-row flex-col bg-blue-gradient ${styles.paddingY}`}>
          <div className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}>
            <div className="flex flex-row justify-center items-center w-full">
              <Form onSubmit={handleOnSubmit}>
                <Form.Field
                  id='form-input-control-email'
                  control={Input}
                  label='Email'
                  name='user_email'
                  placeholder='Email…'
                  required
                  icon='mail'
                  iconPosition='left'
                />
                <Form.Field
                  id='form-input-control-last-name'
                  control={Input}
                  label='Name'
                  name='user_name'
                  placeholder='Name…'
                  required
                  icon='user circle'
                  iconPosition='left'
                />
                <Form.Field
                  id='form-textarea-control-opinion'
                  control={TextArea}
                  label='Message'
                  name='user_message'
                  placeholder='Message…'
                  required
                />
                <Button type='submit' color='blue'>Submit</Button>
              </Form>
            </div>
          </div>

          <div className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}>
            <EarthCanvas />

            {/* gradient start */}
            <div className="absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
            <div className="absolute z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-40" />
            <div className="absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
            {/* gradient end */}
          </div>
        </section> 
        <Footer />
      </section>    
    );
};
export default Contact;

