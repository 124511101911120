import { gas1,
  gas2,
  gas3,
  gas4,
  gas5,
  gas6,
  gas7,
  gas8,
  gas9,
  gas10,
  gas11,
  gas12,
  gas13,
  gas14,
  gas15,
  gas16,  } from "../assets"

const medicalgases = [
  {
    id: 12,
    
    cover:   gas12,
    name: "CYLINDER X CYLINDER FULLY AUTO DIGITAL - MODEL CCU",
    price: 100,
  },
  {
    id: 13,
    
    cover:   gas13,
    name: "LIQUID-X-LIQUID-FULLY-AUTO-DIGITAL-MODEL",
    price: 100,
  },
  {
    id: 14,
    
    cover:   gas14,
    name: "Emergency Reserve Manifold",
    price: 100,
  },
  {
    id: 15,
    
    cover:   gas15,
    name: "Liquid-by-Cylinder Medical Gas Manifold",
    price: 100,
  },
  {
    id: 16,
    
    cover:   gas16,
    name: "vertical one valve box",
    price: 100,
  },
  {
    id: 1,
    
    cover: gas1,
    name: "",
    price: 100,
  },
  {
    id: 2,
     
    cover:   gas2,
    name: "",
    price: 20,
  },
  {
    id: 3,
    
    cover:   gas3,
    name: "",
    price: 200,
  },
  {
    id: 4,
    
    cover:   gas4,
    name: "",
    price: 50,
  },
  {
    id: 5,
    
    cover:   gas5,
    name: "",
    price: 100,
  },
  {
    id: 6,
    
    cover:   gas6,
    name: "",
    price: 100,
  },
  {
    id: 7,
    
    cover:   gas7,
    name: "",
    price: 100,
  },
  {
    id: 8,
    
    cover:  gas8,
    name: "",
    price: 100,
  },
  {
    id: 9,
    
    cover:   gas9,
    name: "",
    price: 100,
  },
  {
    id: 10,
   
    cover:   gas10,
    name: "",
    price: 100,
  },
  {
    id: 11,
    
    cover:   gas11,
    name: "",
    price: 100,
  },
  
];

export default medicalgases;



