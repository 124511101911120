import { React, useState } from "react";
import { Link } from "react-router-dom";
import "../index.css"
import { close, HSL_Logo, menu } from "../assets";
import { navLinks } from "../constants";

const Navbar= () => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);

  return (
      <nav className={`{fix ? 'navbar fixed' : 'navbar'} w-full flex py-6 justify-between items-center navbar`}>
        <Link to="/home">
          <img src={HSL_Logo} alt="HSL" className="w-[100px] h-[50px]" />
        </Link>
        

        <ul className="list-none sm:flex hidden justify-end items-center flex-1">
          {navLinks.map((nav, index) => (
            <li
              key={nav.id}
              className={`font-poppins font-normal cursor-pointer text-[16px] ${
                active === nav.title ? "text-black" : "text-black"
              } ${index === navLinks.length - 1 ? "mr-0" : "mr-10"}`}
              onClick={() => setActive(nav.title)}
            >
              <a href={`${nav.id}`}>{nav.title}</a>
            </li>
          ))}
        </ul>

        <div className="sm:hidden flex flex-1 justify-end items-center">
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="w-[28px] h-[28px] object-contain bg-blue-gradient"
            onClick={() => setToggle(!toggle)}
          />

          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-6 bg-blue-gradient absolute top-10 right-0 mx-0 my-5 min-w-[200px] rounded-xl sidebar z-10`}
          >
            <ul className="list-none flex justify-end items-start flex-1 flex-col">
              {navLinks.map((nav, index) => (
                
                <li
                    key={nav.id}
                    className={`font-poppins font-medium cursor-pointer text-[16px] ${
                      active === nav.title ? "text-white" : "text-white"
                    } ${index === navLinks.length - 1 ? "mb-0" : "mb-3"}`}
                    onClick={() => setActive(nav.title)}
                  >
                    <a href={`${nav.id}`}>{nav.title}</a>
                  
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
  );
};

export default Navbar;

