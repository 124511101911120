import { useState, React } from "react";
import Anaesthesia from "../components/Anaesthesia"
import CSSD from "../components/CSSD"
import Dental from "../components/Dental"
import MedicalGases from "../components/MedicalGases"
import BedheadUnit from "../components/BedheadUnit.jsx"
import MedicalGasesAdapter from "../components/MedicalGasesAdapter";
import Lab from "../components/Lab";
import PendantArm from "../components/PendantArm.jsx";
import MedicalLights from "../components/MedicalLights"
import ChemicalAnalyser from "../components/ChemicalAnalyser"
import Monitors from "../components/Monitors"
import BiosafetyCabinet from "../components/BiosafetyCabinet"
import Radiology from "../components/Radiology"
import SuctionMachine from "../components/SuctionMachine"
import Theatre from "../components/Theatre"

import anaesthesia from "../constants/anaesthesia";
import cssd from "../constants/cssd"
import dental from "../constants/dental";
import medicalgases from "../constants/medicalgases";
import bedheadunit from "../constants/bedheadunit";
import medicalgasesadapter from "../constants/medicalgasesadapter";
import medicallights from "../constants/medicallights";
import chemicalanalyser from "../constants/chemicalanalyser";
import lab from "../constants/lab";
import pendantarm from "../constants/pendantarm"
import monitors from "../constants/monitors";
import biosafetycabinet from "../constants/biosafetycabinet";
import radiology from "../constants/radiology";
import suctionmachine from "../constants/suctionmachine";
import theatre from "../constants/theatre";


import "../components/Products.css"
import styles from "../style";
import "../index.css"
import SlideDeals from "../components/Slider";
import Footer from "../components/Footer"

const Products = () => {
  return (
    <>
      <section className='products' >
        <SlideDeals className={`${styles.boxWidth} z-0`}/>
      </section>
      <section className='products'>
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h1>Medical Gases</h1>
          </div>
          <MedicalGases medicalgases={medicalgases} />
        </div> 
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h1>Bedhead Unit</h1>
          </div>
          <BedheadUnit bedheadunit={bedheadunit} />
        </div>
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h1>Medical Gases Adapters</h1>
          </div>
          <MedicalGasesAdapter medicalgasesadapter={medicalgasesadapter} />
        </div> 
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h1>Lab</h1>
          </div>
          <Lab lab={lab} />
        </div>
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h1>Pendant Arm System</h1>
          </div>
          <PendantArm pendantarm={pendantarm} />
        </div>
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h1>Theatre | Hospital Furniture</h1>
          </div>
          <Theatre theatre={theatre} />
        </div> 
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h1>Chemical Analyzer</h1>
          </div>
          <ChemicalAnalyser chemicalanalyser={chemicalanalyser} />
        </div>  
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h1>Anaesthesia | Respiratory</h1>
          </div>
          <Anaesthesia anaesthesia={anaesthesia} />
        </div> 
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h1>Radiology</h1>
          </div>
          <Radiology radiology={radiology} />
        </div> 
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h1>Medical Lights</h1>
          </div>
          <MedicalLights medicallights={medicallights} />
        </div>
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h1>Dental</h1>
          </div>
          <Dental dental={dental} />
        </div> 
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h1>Biosafety Cabinet</h1>
          </div>
          <BiosafetyCabinet biosafetycabinet={biosafetycabinet} />
        </div>
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h1>Monitors</h1>
          </div>
          <Monitors monitors={monitors} />
        </div>
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h1>Suction Machine</h1>
          </div>
          <SuctionMachine suctionmachine={suctionmachine} />
        </div>
        <div className='container'>
          <div className='heading f_flex'>
            <i className='fa fa-bolt'></i>
            <h1>CSSD</h1>
          </div>
          <CSSD cssd={cssd} />
        </div> 
      </section>
      <div className='container'>
        <Footer />
      </div>
    </>
  )
}

export default Products
