import { eng } from "../assets";

const teamInfo = [
    {
        image: eng,
        title: "Eng. Wananda Joseph",
        decs: "Technical Director",
    },  
];
export default teamInfo
  