import styles, { layout } from "../style";
import Button2 from "./Button2";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { slidersettings } from "./Common";
import 'swiper/css';
import './Services.css';
import sliderdata from '../constants/sliderservices';
import { motion } from "framer-motion";
import { textVariant } from "../components/motion";


const Services = () => (
  <section className={`${styles.paddingY} flex md: flex-col`}>
    <div className={layout.sectionInfo}>
      <h2 className={styles.heading2}>
      Providing our communities with the very best  <br className="sm:block hidden" /> results in healthcare equipment.
      </h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5 text-black`}>
        We take an integrated approach to managing health and safety systems and ensures there are robust management systems in place, so clients receive consistent, well-informed assistance whenever they need it.
      </p>

      <Button2 styles={`mt-10`} />
    </div>

    <section className={layout.section}>
      <div className="paddings innerwidth r-container">
        <Swiper {...slidersettings}>
          <SliderButtons />
          {sliderdata.map((card, i)=> (
              <SwiperSlide>
                <div className="r-card flex md: flex-row">
                  <div className="r-card flex md: flex-col">
                    <span className="secondaryText r-price">
                      <span style={{ color: "blue" }}></span>
                      <span className="color-blue text-[12px]">{card.detail}</span>
                    </span>
                    <span className="primaryText">{card.name}</span>
                  </div>
                  <img src={card.image} alt="" object-contain width={50} height={50} relative z={5}/>
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </section>
  </section>
);

export default Services;

const SliderButtons = ()=> {
  const swiper = useSwiper();
  return (
    <div className="flexCenter r-buttons color-blue">
      <button onClick={()=> swiper.slidePrev()}>&lt</button>
      <button onClick={()=> swiper.slideNext()}>&gt</button>
    </div>
  )
}