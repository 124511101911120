import { analyser1, analyser2, analyser3, analyser4, analyser5, analyser6, analyser7, analyser8, analyser9, analyser10, analyser11, analyser12, analyser13, analyser14, analyser15, } from "../assets"

const chemicalanalyser = [
  {
    id: 1,
    
    cover: analyser1,
    name: "Auto Hematology Analyzer",
    price: 100,
  },
  {
    id: 2,
    
    cover: analyser2,
    name: "Auto Chemistry Analyzer BK-200",
    price: 20,
  },
  {
    id: 3,
    
    cover: analyser3,
    name: "Auto Chemistry Analyzer BK-280",
    price: 200,
  },
  {
    id: 4,
    
    cover: analyser4,
    name: "Auto Chemistry Analyzer BK-600",
    price: 50,
  },
  {
    id: 5,
    
    cover: analyser5,
    name: "Auto ELISA Processor (BIOBASE2000)",
    price: 100,
  },
  {
    id: 6,
    

    cover: analyser6,
    name: "Auto ELISA Processor BIOBASE4001",
    price: 100,
  },
  {
    id: 7,
    

    cover: analyser7,
    name: "Automatic Veterinary 3-Diff Hematology Analyzer",
    price: 100,
  },
  {
    id: 8,
    

    cover: analyser8,
    name: "ELISA Microplate Reader",
    price: 100,
  },
  {
    id: 9,
    

    cover: analyser9,
    name: "Elisa Microplate Washer BK-9622",
    price: 100,
  },
  {
    id: 10,
    

    cover: analyser10,
    name: "Fluorescence Immunoassay Analyzer BKP2000",
    price: 100,
  },
  {
    id: 11,
    

    cover: analyser11,
    name: "Fluorescence Immunoassay Analyzer BKP3000",
    price: 100,
  },
  {
    id: 12,
    

    cover: analyser12,
    name: "Microplate Shaker",
    price: 100,
  },
  {
    id: 13,
    

    cover: analyser13,
    name: "Semi-auto Chemistry Analyzer",
    price: 100,
  },
  {
    id: 14,
    

    cover: analyser14,
    name: "Urine Analyzer",
    price: 100,
  },
  {
    id: 15,
    

    cover: analyser15,
    name: "Water Purifier",
    price: 100,
  },
];

export default chemicalanalyser;



